(function ( $ ) {

    var defaultOptions = {
        slidesPerView: 4,
        spaceBetween: 30,
        slidesPerGroup: 5,
        watchOverflow: true,
        loop: false,
        loopFillGroupWithBlank: false,
        pagination: {
            clickable: true
        },
        breakpoints: {
            1024: {
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 50
            },
            992: {
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 40
            },
            768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                spaceBetween: 30
            },
            640: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 20
            },
            320: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 10
            }
        }
    };

    function get_pagination( object, parent ) {
        var post_meta_name = $( parent ).find( '.post-meta-name' ).val();// get the custom field name, is a unique name in the page
        object[ 'pagination' ] = { // search the unique pagination element
            el: $( parent ).find( '.swiper-pagination.' + post_meta_name )
        };
        object[ 'navigation' ] = { //search the unique navigation buttons
            nextEl: $( parent ).find( '.swiper-button-next.' + post_meta_name ),
            prevEl: $( parent ).find( '.swiper-button-prev.' + post_meta_name )
        };
        return object;
    }

    // widget carousels
    $( '.swiper-container-widget' ).each( function () {
        var newOptions = { // set the options that overrides the default options
            slidesPerView: 2,
            spaceBetween: 40,
            slidesPerGroup: 2,
            breakpoints: {
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 50
                },
                992: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 40
                },
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 30
                },
                640: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 20
                },
                320: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 10
                }
            }
        }; // set the options that overrides the default options
        new Swiper( $( this ), $.extend( true, $.extend( true, {}, defaultOptions ), get_pagination( newOptions, this ) ) ); // the "interior extend" copies the defaultOptions object. The "exterior extend" overwrites the default options with the custom options
    } );

    $( '.swiper-container-product' ).each( function () {
        var newOptions = {}; // set the options that overrides the default options
        new Swiper( $( this ), $.extend( true, $.extend( true, {}, defaultOptions ), get_pagination( newOptions, this ) ) ); // the "interior extend" copies the defaultOptions object. The "exterior extend" overwrites the default options with the custom options
    } );

    if ($( window ).width() > 768  && $( window ).width() < 992 && $('#mega-menu > li').length > 4)  {
        $( '#site-navigation' ).addClass( 'margin-top-for-space' );
    }

    $( '.swiper-container-category' ).each( function () {
        var newOptions = { // set the options that overrides the default options
            slidesPerView: 4,
            spaceBetween: 45,
            slidesPerGroup: 4,
            breakpoints: {
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 45
                },
                992: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 45
                },
                768: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 45
                },
                640: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 20
                },
                320: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 10
                }
            }
        };
        new Swiper( $( this ), $.extend( true, $.extend( true, {}, defaultOptions ), get_pagination( newOptions, this ) ) ); // the "interior extend" copies the defaultOptions object. The "exterior extend" overwrites the default options with the custom options
    } );

    $( '.swiper-container-brand' ).each( function () {
        var newOptions = { // set the options that overrides the default options
            slidesPerView: 4,
            spaceBetween: 40,
            slidesPerGroup: 4,
            breakpoints: {
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 50
                },
                992: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 40
                },
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 30
                },
                640: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 20
                },
                320: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 10
                }
            }
        }; // set the options that overrides the default options
        new Swiper( $( this ), $.extend( true, $.extend( true, {}, defaultOptions ), get_pagination( newOptions, this ) ) ); // the "interior extend" copies the defaultOptions object. The "exterior extend" overwrites the default options with the custom options
    } );

    $( '.swiper-container-press' ).each( function () {
        var newOptions = { // set the options that overrides the default options
            slidesPerView: 4,
            spaceBetween: 45,
            slidesPerGroup: 4,
            breakpoints: {
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 45
                },
                992: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 50
                },
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 30
                },
                640: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 20
                },
                320: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 10
                }
            }
        }; // set the options that overrides the default options
        new Swiper( $( this ), $.extend( true, $.extend( true, {}, defaultOptions ), get_pagination( newOptions, this ) ) ); // the "interior extend" copies the defaultOptions object. The "exterior extend" overwrites the default options with the custom options
    } );

    $( '.swiper-container-press-pdp' ).each( function () {
        var newOptions = { // set the options that overrides the default options
            slidesPerView: 2,
            spaceBetween: 0,
            slidesPerGroup: 1,
            breakpoints: {
                1024: {
                    slidesPerView: 2,
                    slidesPerGroup: 1,
                    spaceBetween: 0
                },
                992: {
                    slidesPerView: 2,
                    slidesPerGroup: 1,
                    spaceBetween: 0
                },
                768: {
                    slidesPerView: 2,
                    slidesPerGroup: 1,
                    spaceBetween: 0
                },
                640: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 0
                },
                320: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 0
                }
            }
        }; // set the options that overrides the default options
        new Swiper( $( this ), $.extend( true, $.extend( true, {}, defaultOptions ), get_pagination( newOptions, this ) ) ); // the "interior extend" copies the defaultOptions object. The "exterior extend" overwrites the default options with the custom options
    } );

    $( '.swiper-container-products' ).each( function () {
        var newOptions = { // set the options that overrides the default options
            slidesPerView: 3,
            spaceBetween: 0,
            slidesPerGroup: 3,
            watchOverflow: true,
            breakpoints: {
                1024: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 0
                },
                992: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 0
                },
                768: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 0
                },
                640: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 30
                },
                320: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 30
                }
            }
        }; // set the options that overrides the default options
        new Swiper( $( this ), $.extend( true, $.extend( true, {}, defaultOptions ), get_pagination( newOptions, this ) ) ); // the "interior extend" copies the defaultOptions object. The "exterior extend" overwrites the default options with the custom options
    } );

    $( '.swiper-container-partner' ).each( function () {
        var newOptions = { // set the options that overrides the default options
            slidesPerView: 4,
            spaceBetween: 45,
            slidesPerGroup: 4,
            breakpoints: {
                992: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 45
                },
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 45
                },
                768: {
                    spaceBetween: 45
                },
                640: {
                    spaceBetween: 25
                },
                320: {
                    spaceBetween: 25
                }
            }
        };
        new Swiper( $( this ), $.extend( true, $.extend( true, {}, defaultOptions ), get_pagination( newOptions, this ) ) ); // the "interior extend" copies the defaultOptions object. The "exterior extend" overwrites the default options with the custom options
    } );

    $( '.swiper-container-header' ).each( function () {
        var newOptions = { // set the options that overrides the default options
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 0,
            loop: true,
            autoplay:{
                delay: 6000,
            },
            effect: 'fade',
            fadeEffect:{
              crossFade: true,  
            },
            keyboard: {
              enabled: true,
            },
            // loopFillGroupWithBlank: true,
            breakpoints: {
                1024: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                992: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0
                }
            }
        };
        new Swiper( $( this ), $.extend( true, $.extend( true, {}, defaultOptions ), get_pagination( newOptions, this ) ) ); // the "interior extend" copies the defaultOptions object. The "exterior extend" overwrites the default options with the custom options
    } );

    // swiper: upsells, bottom of product page
    var mySwiper = new Swiper ('.swiper-upsells', {
        direction: 'horizontal',
        slidesPerView: 3,
        breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        998: {
          slidesPerView: 2,
          spaceBetween: 30
        }
        },
        navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        }
    })

    // swiper: cross-sells, bottom left of cart checkout
    var mySwiper = new Swiper ('.swiper-cross-sells', {
        direction: 'horizontal',
        slidesPerView: 2,
        spaceBetween: 40,
        breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        998: {
          slidesPerView: 2,
          spaceBetween: 30
        }
        },
        navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        }
    })
    

    $( '.swiper-container-whatsnew' ).each( function () {
        var newOptions = { // set the options that overrides the default options
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 100,
            // loopFillGroupWithBlank: true,
            breakpoints: {
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 100
                },
                992: {
                    slidesPerView: 2,
                    spaceBetween: 100
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 100
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0 
                }
            }
        };
        new Swiper( $( this ), $.extend( true, $.extend( true, {}, defaultOptions ), get_pagination( newOptions, this ) ) ); // the "interior extend" copies the defaultOptions object. The "exterior extend" overwrites the default options with the custom options
    } );

})( jQuery );

// hides empty facetwp values on directory pages
// this is clunky, but FacetWP docs suggest similar solutions...
jQuery(document).ready(function() {
    // hides empty price selection
    if (jQuery('li[data-facet="price"] .facetwp-selection-value').is(':empty')){
      jQuery('li[data-facet="price"]').css('display', 'none');
    }

    addContainerInWishlistMsg(); 

    addContainerInEditWishlist(); 
});


function addContainerInWishlistMsg() {
//    jQuery('#content > div > .woocommerce').addClass('container');
    var editPage = jQuery('.plm-wishlist.edit');
    if (editPage) {
        (editPage).parents("#primary").siblings( ".woocommerce").addClass('container');
    }
}


function addContainerInEditWishlist() {
    var editPage = jQuery('.plm-wishlist.edit');
    if (editPage) {
        (editPage).parents( "#primary").addClass('plm-wishlist-container');
    }
}



jQuery(document).ready(function() {
    jQuery('.page-numbers li a').click(function(){
        jQuery('.page-numbers').removeClass('active');
        jQuery(this).addClass('active');
    });
    jQuery('.page-numbers li span').click(function(){
        jQuery('.page-numbers').removeClass('active');
        jQuery(this).addClass('active');
        FWP.soft_refresh = true;
        FWP.refresh();
    });
    jQuery("#bazzarvoice_product_rating_summary").insertBefore(".single-product div.product p.price");

    var uri = window.location.toString();
    if (uri.indexOf("#tab-description") > 0) {
        var clean_uri = uri.substring(0, uri.indexOf("#tab-description"));
        window.history.replaceState({}, document.title, clean_uri);
    }
});
